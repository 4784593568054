import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import axios from "axios";
import moment from "moment"
import Select from "react-select"
import InputMask from 'react-input-mask';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { withTranslation } from 'react-i18next';

// reactstrap components
import {
  Button,
  Input,
  Row,
  Col,
  Form,
  FormGroup
} from "reactstrap";

import { connect } from "react-redux";
import {userCant, userCan} from "../../../services/Authorization/authService";

function mapStateToProps(state) {
  console.log('Redux state update:', state);
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate,
    user: state.auth.user,
    rentalCompanyTableData: state.allOtherReducers.rentalCompanyTableData,
  };
}

const mapDispatchToProps = (dispatch) => ({
  updateRentalEquipmentTableData: (data) => {
    console.log('Dispatching update with data:', data);
    dispatch({ 
      type: 'UPDATE_RENTAL_EQUIPMENT_DATA', 
      payload: data 
    });
  },
  getSomeRentalEquipment: () => dispatch({ 
    type: 'FETCH_RENTAL_EQUIPMENT' 
  }),
});

class AddRentalCompanyTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.rentalEquipmentTableData || [],
      description:"",
      typeModel:"",
      projectId: "ettest",
      rentalCompanyToAddValue: '',
      singleRentalEquipmentSelect: '',
      singleRentalEquipmentDescription:'BOOM',
      companyFromAPI: [],
      selectedValueCompany: '',
      selectedValueDescriptionm: '',
      selectedValueTypeModel: '',
      editContent: false,
      editingRow: -1,
      isDirty: false,
      company:null,
      deleteRentalEquipmentAlert: null,
      unableToDeleteRentalEquipmentAlert: null,
    }
    this.clearForm=this.clearForm.bind(this)
    this.handleDescriptionChange=this.handleDescriptionChange.bind(this)
    this.handleTypeModelChange=this.handleTypeModelChange.bind(this)
    this.renderEditable = this.renderEditable.bind(this);
    this.saveRowData = this.saveRowData.bind(this);
    this.toggleEditable = this.toggleEditable.bind(this);
    this.renderActiveButtons = this.renderActiveButtons.bind(this);
    this.deleteRentalEquipmentById=this.deleteRentalEquipmentById.bind(this)
    this.renderDropdownEditable=this.renderDropdownEditable.bind(this)
    this.deleteRentalEquipmentAlert = this.deleteRentalEquipmentAlert.bind(this)
    this.warningRentalEquipmentCantBeDeleted = this.warningRentalEquipmentCantBeDeleted.bind(this)

  }

  async componentDidMount() {
    console.log('Component mounted');
    console.log('Initial props:', this.props);
    
    // Immediately fetch rental companies
    await this.fetchRentalCompanies();
    
    if (this.props.projectId) {
      await this.fetchRentalEquipment(this.props.projectId);
    }
  }

  componentDidUpdate(prevProps) {
    console.log('Component updating');
    console.log('Previous props:', prevProps);
    console.log('Current props:', this.props);

    // Check for project change
    if (this.props.projectId !== prevProps.projectId) {
      console.log('Project changed, fetching new data...');
      this.fetchRentalCompanies();
      this.fetchRentalEquipment(this.props.projectId);
    }

    // Log rental company data changes
    if (this.props.rentalCompanyTableData !== prevProps.rentalCompanyTableData) {
      console.log('Rental company data changed:', this.props.rentalCompanyTableData);
    }
  }

  fetchRentalEquipment = async (projectId) => {
    if (!projectId) {
      console.log('No projectId provided');
      return;
    }
    
    try {
      console.log('Fetching rental equipment for project:', projectId);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/rentalequipment/project/${projectId}`,
        {
          headers: {
            'Authorization': localStorage.jwtToken
          }
        }
      );
      
      console.log('Received rental equipment data:', response.data);
      
      // Update both local state and Redux
      this.setState({ data: response.data }, () => {
        console.log('State updated after fetch:', this.state.data);
      });
      
      // Notify parent component
      if (this.props.updateRentalEquipmentTableData) {
        this.props.updateRentalEquipmentTableData(response.data);
      }
    } catch (error) {
      console.error('Error fetching rental equipment:', error);
    }
  };

  fetchRentalCompanies = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/rentalcompany/`,
        {
          headers: {
            'Authorization': localStorage.jwtToken
          }
        }
      );
      
      const options = response.data.map(company => ({
        value: company._id,
        label: company.name || 'Unnamed Company'
      }));
      
      this.setState({ companyFromAPI: options });
      
    } catch (error) {
      console.error('Error fetching rental companies:', error);
      this.setState({ companyFromAPI: [] });
    }
  };

  populateDropDownCompany(data) {
    console.log('Populating dropdown with data:', data);
    
    if (!Array.isArray(data)) {
      console.error('Invalid rental company data:', data);
      return;
    }

    const options = data.map(company => ({
      value: company._id,
      label: company.name
    }));

    console.log('Setting company options:', options);
    
    this.setState({ 
      companyFromAPI: options 
    }, () => {
      console.log('Company options set in state:', this.state.companyFromAPI);
    });
  }

  formSubmitSuccess = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    
    try {
      // Use the selected company from state
      const companyValue = this.state.company?.value;
      if (!companyValue) {
        console.error('No company selected');
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/rentalequipment/`,
        {
          rentalCompany: companyValue,
          description: this.state.description,
          typeModel: this.state.typeModel,
          projectId: this.props.projectId
        },
        {
          headers: {
            'Authorization': localStorage.jwtToken
          }
        }
      );

      // Refresh the equipment list
      this.fetchRentalEquipment(this.props.projectId);
      
      // Clear the form
      this.setState({
        company: null,
        description: '',
        typeModel: ''
      });
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  // Update the company select handler
  handleCompanyChange = (selectedOption) => {
    console.log('Selected company:', selectedOption);
    this.setState({ company: selectedOption });
  };

  handleRentalEquipmentToAddText = (e) => {

    this.setState({ rentalEquipmentToAddValue: e.target.value }, () => this.state.rentalEquipmentToAddValue);
  }


  componentWillReceiveProps({ rentalEquipmentTableData, rentalCompanyTableData }) {
    this.setState({ ...this.state, rentalEquipmentTableData, rentalCompanyTableData }, () => this.state)
  }

  clearForm(){
    this.setState({
      company:'',
      description: '',
      typeModel: ''
    }, ()=>{console.log(this.state.description)})
  }

  handleDescriptionChange(e){
    console.log( "e.target.value Description")
    console.log( e.target.value)
    this.setState({description: e.target.value})
  }

  handleTypeModelChange(e){
    console.log( "e.target.value TypeModel")
    console.log( e.target.value)
    this.setState({typeModel: e.target.value})
  }

  dataTablelength(obj) {
    console.log(obj)
    return Object.keys(obj).length;
}

handleInputChange = (cellInfo, event) => {
  let data = [...this.props.rentalEquipmentTableData];
  data[cellInfo.index][cellInfo.column.id] = event.target.value;

  this.setState({ data });
};

handleSelectInputChange= (cellInfo, event) => {
  console.log(event)
  let data = [...this.props.rentalEquipmentTableData];
  data[cellInfo.index][cellInfo.column.id] = event.value;

  this.setState({ data });
  this.setState({company:event.value}, ()=>{})
};

renderEditable(cellInfo) {

  const cellValue = this.props?.rentalEquipmentTableData[cellInfo.index][cellInfo.column.id];

  const { editingRow } = this.state;
  return (
    <div>
      {editingRow === cellInfo.index ? (
        <Input
          maskChar=""
          mask=""
          placeholder=""
          onChange={this.handleInputChange.bind(null, cellInfo)}
          value={cellValue}
          tag={InputMask}
        />
      ) : (<div >
        {cellInfo.value}
      </div>)}
    </div>
  );
}

renderDropdownEditable(cellInfo) {
  if (!cellInfo || !cellInfo.original) {
    return null;
  }

  const cellValue = this.props.rentalEquipmentTableData[cellInfo.index][cellInfo.column.id];
  const { editingRow } = this.state;

  return (
    <div>
      {editingRow === cellInfo.index ? (
        <Select
          className="react-select primary"
          classNamePrefix="react-select"
          name="company"
          defaultValue={{ 
            label: cellInfo.value, 
            value: cellInfo.original.rentalCompany?._id 
          }}
          onChange={this.handleSelectInputChange.bind(null, cellInfo)}
          options={this.state.companyFromAPI}
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 9999,
              color: '#000',
              backgroundColor: '#fff'
            }),
            option: (provided, state) => ({
              ...provided,
              color: state.isSelected ? '#fff' : '#000',
              backgroundColor: state.isSelected ? '#007bff' : '#fff',
              '&:hover': {
                backgroundColor: '#f8f9fa',
                color: '#000'
              },
              cursor: 'pointer',
              padding: '8px 12px'
            }),
            control: (provided) => ({
              ...provided,
              minHeight: '38px'
            })
          }}
          menuPortalTarget={document.body}
        />
      ) : (
        <div>{cellInfo.value || ''}</div>
      )}
    </div>
  );
}

saveRowData(cellInfo) {
  console.log("cellInfo")
  console.log(cellInfo)
  this.setState({ editContent: false }, () => console.log(cellInfo.original.name))

  axios.put(process.env.REACT_APP_API_URL + '/api/rentalequipment/', {
    _id: cellInfo.original._id,
    typeModel: cellInfo.original.typeModel,
    description: cellInfo.original.description,
    company:cellInfo.original.company

  }, {
    headers: {
      'Authorization': localStorage.jwtToken
    }
  }).then(res => {
    console.log(res)
    this.setState({
      editingRow: -1,
      isDirty: false
    })
    return res.data
  })
    .catch((error) => {
      ;
    })
}


toggleEditable(index) {
  console.log(index);
  const { isDirty } = this.state;
  if (!isDirty) {
    this.setState({
      editingRow: index,
      isDirty: true
    });
  }
  else {
    this.setState({
      editingRow: -1,
      isDirty: false
    })
  }
}


renderActiveButtons(row) {

  if (userCant(this.props.user, 'equipment.manage')) {
    return ''
  }

    return (<div>
    <Button
      onClick={() => this.toggleEditable(row.index)}
      size="xs"
      className="btn-icon btn-neutral btn btn-info"
    ><i className="fa fa-edit"
      /></Button>
    <Button
      onClick={() => this.saveRowData(row)}
      size="xs"
      className="btn-icon btn-neutral btn btn-info"
    ><i className="fa fa-save"
      /></Button>
          <Button
        color="danger"
        size="xs"
        className="btn-icon btn-neutral btn btn-info"
        onClick={() => {
          this.deleteRentalEquipmentAlert(row.original)
        }
        }
      ><i
          className="fa fa-remove"
        />
      </Button>
  </div>)


}
deleteRentalEquipmentById(rentalEquipmentId) {
  var self = this
  axios.delete(process.env.REACT_APP_API_URL + '/api/rentalequipment/' + rentalEquipmentId, {
    headers: {
      'Authorization': localStorage.jwtToken
    }
  }).then(res => {
    this.setState({deleteRentalEquipmentAlert:null}, ()=>{})
    this.props.getSomeRentalEquipment()
    return res
  })
  .catch(function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status === 403 || error.response.status===409 ) {
        self.warningRentalEquipmentCantBeDeleted()
        self.setState({ deleteRentalEquipmentAlert: null }, () => { })
        //  return self.setState({responseOnDeleteRequest:'Delete associated rental equipment first'},()=>{})
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
  });
}

deleteRentalEquipmentAlert(rentalId) {
console.log(rentalId)
  this.setState({
    deleteRentalEquipmentAlert:
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        onConfirm={() => this.deleteRentalEquipmentById(rentalId._id)}
        onCancel={() => this.setState({ deleteRentalEquipmentAlert: null }, () => { })}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText={this.props.t("Delete")}
        cancelBtnText={this.props.t("Cancel")}
        showCancel
      >
        {this.props.t("Delete Rental Equipment")}
        <br/>
        {rentalId?.rentalCompany?.name + ' | ' + rentalId?.description + ' | ' + rentalId?.typeModel}

      </ReactBSAlert>
  }, () => { })
}

warningRentalEquipmentCantBeDeleted(){
  this.setState({
    visible:
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        onConfirm={() => this.setState({ visible: null }, () => { })}
        // onCancel={() => this.setState({ deleteRentalCompanyAlert: null }, () => { })}
        confirmBtnBsStyle="info"
        // cancelBtnBsStyle="danger"
        confirmBtnText={this.props.t("Close")}
        // cancelBtnText="Cancel"
        // showCancel
      >
        {this.props.t("Unable to delete rental equipment as its associated with a rental record")}.

      </ReactBSAlert>
  }, () => { })
}




  render() {
    const { companyFromAPI, company } = this.state;
    console.log('Render - Available options:', companyFromAPI);
    console.log('Render - Selected company:', company);

    return (
      <>
          {this.state.deleteRentalEquipmentAlert}
          {this.state.visible}
        <div className="content">
          {userCan(this.props.user, 'equipment.manage') && (
            <>
              <h4>{this.props.t("Add Rental Equipment")}</h4>
              <Form className="form-horizontal" onSubmit={this.formSubmitSuccess}>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label>{this.props.t("Rental Company")}</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="rentalCompany"
                        value={company}
                        onChange={(selectedOption) => {
                          this.setState({ company: selectedOption });
                        }}
                        options={companyFromAPI}
                        placeholder={this.props.t("Select Rental Company")}
                        isSearchable={true}
                        isClearable={true}
                        styles={{
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 9999,
                            color: '#000',
                            backgroundColor: '#fff'
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            color: state.isSelected ? '#fff' : '#000',
                            backgroundColor: state.isSelected ? '#007bff' : '#fff',
                            '&:hover': {
                              backgroundColor: '#f8f9fa',
                              color: '#000'
                            },
                            cursor: 'pointer',
                            padding: '8px 12px'
                          }),
                          control: (provided) => ({
                            ...provided,
                            minHeight: '38px'
                          })
                        }}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: '#007bff',
                            primary25: '#f8f9fa',
                            neutral0: '#fff',
                            neutral80: '#000'
                          },
                        })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <label>{this.props.t("Description")}</label>
                      <Input
                          onChange={this.handleDescriptionChange}
                          name="description"
                          value={this.state.description}
                          type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <label>{this.props.t("Type / Model")}</label>
                      <Input
                          onChange={this.handleTypeModelChange}
                          name="typemodel"
                          value={this.state.typeModel}
                          type="text"
                      />
                    </FormGroup>
                  </Col>

                </Row>
                <Row>
                  <Col md={3}>
                    <Button color="info" type="submit">{this.props.t("Add Rental Equipment")}</Button>
                  </Col>
                </Row>
              </Form>
            </>
          )}

          <ReactTable
            data={this.state.data}
            columns={[
              {
                Header: this.props.t("Rental Equipment"),
                accessor: "_id",
                show: false
              }, {
                Header:this.props.t("Rental Equipment Company"),
                accessor: "rentalCompany.name",
                Cell: this.renderDropdownEditable
              },
              {
                Header: this.props.t("Equipment Description"),
                accessor: "description",
                Cell: this.renderEditable
              }, {
                Header: this.props.t("Type / Model"),
                accessor: "typeModel",
                Cell: this.renderEditable
              },
              {
                Header: this.props.t("Actions"),
                id: "activateToggle",
                width: 150,
                Cell: ((value) => (this.renderActiveButtons(value)))
              }

              // {
              //   Header: "Actions",
              //   accessor: "actions",
              //   sortable: false,
              //   filterable: false,
              //   Cell: (row) => (
              //     <Button
              //       color="danger"
              //       size="sm"
              //       className="btn-icon btn-link remove"
              //       onClick={() => {
              //         this.deleteSiteLocationBySiteLocationId(row.original._id)

              //       }}>
              //       <i className="fa fa-times" />
              //     </Button>
              //   )
              // },

            ]}
            defaultPageSize={1}
            pageSize={this.state.data.length}
            showPaginationTop={false}
            showPaginationBottom={false}

            // getTrProps={this.onRowClick}
            // getTrProps={onRowClick}
            /*
              You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
            */
            className="-striped -highlight primary-pagination"
          />
        </div>
      </>
    );
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AddRentalCompanyTable))

