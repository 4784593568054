import plus from '../../../assets/img/plus.svg'
import minus from '../../../assets/img/minus.svg'
import { useState } from 'react'

export const TagList = ({ tags = [], handleUpdateSelectedTags, selectedTags, indeterminateTags = [] }) => {
	const [isSelected, setIsSelected] = useState(0)

	const isChildExist = (tag) => {
		return tags.findIndex(t => t.parent_id === tag._id) >= 0
	}

	const findChildren = (tag) => {
		return tags.filter(t => t.parent_id === tag._id) || []
	}

	const renderTagCheckbox = (tag) => {
		let nameSpan = tag.imgs_count
			? <span>{tag.name} {tag.imgs_count && <>({tag.imgs_count})</>}</span>
			: <span>{tag.name}</span>

		return (
			<div className="photo__tag__child pointer" key={tag._id} onClick={() => handleUpdateSelectedTags(tag)}>
				<input
					ref={input => {
						if (input) {
							if (indeterminateTags.find(it => `${it._id}` == `${tag._id}`) && !selectedTags.find(st => `${st._id}` == `${tag._id}`)) {
								input.indeterminate = true;
							} else {
								input.indeterminate = false
							}
						}
					}}
					 type="checkbox" checked={selectedTags.findIndex(t => t._id === tag._id) >= 0} onChange={(e) => {
					e.stopPropagation()
					handleUpdateSelectedTags(tag)
				}} />
				{nameSpan}
			</div>
		)
	}

	return (
		tags.filter(t => !t.parent_id).map((topLevelTag, i) => (
			<div className="photo__tag" key={topLevelTag._id}>
				<div className="photo__tag__header" onClick={() => setIsSelected(isSelected === i + 1 ? 0 : i + 1)}>
					<span>{topLevelTag.name}</span>
					<img className="pointer" src={isSelected === i + 1 ? minus : plus} />
				</div>
				<div className='photo__tag__body' style={{"padding":"0 16px"}}>
					{isSelected === i + 1 && findChildren(topLevelTag).map(tag => (
						isChildExist(tag)
							? (
								<div className='photo__tag__sublevel' key={tag._id}>
									<span>{tag.name}</span>
									<div className='photo__tag__sublevel__list'>
										{findChildren(tag).map(renderTagCheckbox)}
									</div>
								</div>
							)
							: renderTagCheckbox(tag)
					))}
				</div>
			</div>
		))
	)
}