import React from 'react';
import UploadCompanyLogo from "./UploadCompanyLogo";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, Input, Label, FormGroup} from "reactstrap";
import classnames from "classnames";
import axios from "axios";
import {setClientObject} from "../../actions/authActions";
import {isSuperuser} from "../../services/Authorization/authService";

/**
 *
 */
class ClientConfiguration extends React.Component {

    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);

        const client = props.client || {};
        let clientConfig = client.config || {};

        this.state = {
            activeFormTab: 'configuration',
            clientName: client.name,
            supportMultipleLanguages: clientConfig.supportMultipleLanguages,
            enableCustomFields: clientConfig.enableCustomFields,
            enableFilesManager: clientConfig.enableFilesManager,
            errors: {},
            toastMessage: ''
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.toggleActiveTabForm = this.toggleActiveTabForm.bind(this);
    }

    /**
     *
     * @param tabId
     */
    toggleActiveTabForm(tabId) {
        if (this.state.activeFormTab !== tabId) {
            this.setState({
                activeFormTab: tabId
            });
        }
    }

    componentDidMount() {
        if (!isSuperuser(this.props.user)) {
            return this.props.history.push('/admin/instructions')
        }
    }

    /**
     *
     */
    onSubmit(e) {
        e.preventDefault();

        let clientData = {
            clientName: this.state.clientName,
            breakTime: this.state.breakTime,
            supportMultipleLanguages: this.state.supportMultipleLanguages,
            enableCustomFields: this.state.enableCustomFields,
            enableFilesManager: this.state.enableFilesManager,
        };

        const url = `${process.env.REACT_APP_API_URL}/api/client/${this.props.client._id}/save-configuration`

        axios.put(url, clientData, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            this.props.setClientObject(res.data);

            this.setState({
                toastMessage: this.props.t('Client configuration successfully saved!')
            })
            setTimeout(() => {
                this.setState({
                    toastMessage: ''
                })
            }, 2500)

        }).catch((error) => {
            if (error?.response?.data) {
                this.setState({
                    errors: error.response.data
                })
            }
        })
    }

    /**
     *
     * @returns {JSX.Element}
     */
    render() {

        if (!isSuperuser(this.props.user)) {
            return this.props.history.push('/admin/instructions')
        }

        let {errors} = this.state;

        return <div className="content">
            <Row>
                <Col md={12}>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: this.state.activeFormTab === 'configuration'
                                })}
                                onClick={() => {
                                    this.toggleActiveTabForm('configuration')
                                }}
                            >
                                {this.props.t('Configuration')}
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: this.state.activeFormTab === 'logo'
                                })}
                                onClick={() => {
                                    this.toggleActiveTabForm('logo')
                                }}
                            >
                                {this.props.t('logo')}
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeFormTab}>
                        <TabPane tabId="logo">
                            <Row className={'m-3'}>
                                <Col md={12}>
                                    <UploadCompanyLogo/>
                                </Col>
                            </Row>
                        </TabPane>

                        <TabPane tabId='configuration'>
                            <form noValidate onSubmit={this.onSubmit}>

                                <Row className={'m-3'}>
                                    <Col md={6}>
                                        <FormGroup>
                                            <label>{this.props.t('Client Name')}</label>
                                            <Input
                                                name="clientName"
                                                type="text"
                                                className={classnames('form-control form-control-lg', {
                                                    'is-invalid': errors.clientName
                                                })}
                                                onChange={(e) => this.setState({clientName: `${e.target.value}`})
                                                }
                                                value={this.state.clientName}

                                            />
                                            {errors.clientName && (
                                                <div className="invalid-feedback">{errors.clientName}</div>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className={'m-3'}>
                                    <Col md={6}>
                                        <Row>
                                            <Col className={'ml-3'} md={6}>
                                                <FormGroup className="d-inline mr-2">

                                                    <Input
                                                        checked={!!this.state.supportMultipleLanguages}
                                                        placeholder={this.props.t('Support Multiple Languages')}
                                                        type="checkbox"
                                                        className={classnames({
                                                            'is-invalid': errors.supportMultipleLanguages
                                                        })}
                                                        id={'supportMultipleLanguages'}
                                                        name="supportMultipleLanguages"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                [e.target.name]: !!e.target.checked
                                                            })
                                                        }}
                                                    />
                                                    <Label
                                                        for={'supportMultipleLanguages'}
                                                        className="ml-1">{this.props.t('Support Multiple Languages')}</Label>
                                                    {errors.supportMultipleLanguages && (
                                                        <div
                                                            className="invalid-feedback">{errors.supportMultipleLanguages}</div>
                                                    )}
                                                </FormGroup>

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col className={'ml-3'} md={6}>
                                                <FormGroup className="d-inline mr-2">

                                                    <Input
                                                        checked={!!this.state.enableCustomFields}
                                                        placeholder={this.props.t('Enable Custom Fields')}
                                                        type="checkbox"
                                                        className={classnames({
                                                            'is-invalid': errors.enableCustomFields
                                                        })}
                                                        id={'enableCustomFields'}
                                                        name="enableCustomFields"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                [e.target.name]: !!e.target.checked
                                                            })
                                                        }}
                                                    />
                                                    <Label
                                                        for={'enableCustomFields'}
                                                        className="ml-1">{this.props.t('Enable Custom Fields')}</Label>
                                                    {errors.enableCustomFields && (
                                                        <div
                                                            className="invalid-feedback">{errors.enableCustomFields}</div>
                                                    )}
                                                </FormGroup>

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className={'ml-3'} md={6}>
                                                <FormGroup className="d-inline mr-2">

                                                    <Input
                                                        checked={!!this.state.enableFilesManager}
                                                        placeholder={this.props.t('Enable File Manager')}
                                                        type="checkbox"
                                                        className={classnames({
                                                            'is-invalid': errors.enableFilesManager
                                                        })}
                                                        id={'enableFilesManager'}
                                                        name="enableFilesManager"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                [e.target.name]: !!e.target.checked
                                                            })
                                                        }}
                                                    />
                                                    <Label
                                                        for={'enableFilesManager'}
                                                        className="ml-1">{this.props.t('Enable File Manager')}</Label>
                                                    {errors.enableFilesManager && (
                                                        <div
                                                            className="invalid-feedback">{errors.enableFilesManager}</div>
                                                    )}
                                                </FormGroup>

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row className={'m-3'}>
                                    <Col md={3}>
                                        <Row>
                                            <Col md={12}>
                                                <input type="submit"
                                                       value={'save'}
                                                       className="btn btn-info btn-block "/>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </form>
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>

            {this.state.toastMessage && (
                <div className="photo__options__toast bg-success">
                    <a href={'#'} onClick={() => this.setState({toastMessage: ''})}>
                        <i className={'fa fa-times text-dark'}></i>
                    </a>
                    <span>{this.state.toastMessage}</span>
                </div>
            )}
        </div>
    }
}

/**
 *
 * @param state
 * @returns {{reportDate, user, projectId}}
 */
const mapStateToProps = state => ({
    user: state.auth.user,
    projectId: state.allOtherReducers.projectId,
    reportDate: state.allOtherReducers.reportDate,
    client: state.auth.client
});

const mapDispatchToProps = dispatch => {
    return {
        setClientObject: client => dispatch(setClientObject(client)),
    }
}

/**
 *
 */
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ClientConfiguration));

