import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Zoom from '@material-ui/core/Zoom';
import Translate from '../../../../utils/FileManager/Translate';
import InputField from './InputField';
import useStyles from './Styles';
import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import {Document, Page, pdfjs} from 'react-pdf';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Zoom in={props.open} ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
    const classes = useStyles();
    const {open, title, description, handleClose, handleSubmit, nameInputSets, viewerType = 'images', path} = props;
    const nameValue = typeof nameInputSets.value !== undefined ? nameInputSets.value : '';
    const [renameText, setRenameText] = useState(nameValue);

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    });

    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
    };

    const goToPrevPage = () =>
        setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

    const goToNextPage = () =>
        setPageNumber(
            pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
        );


    const handleNameChange = value => {
        setRenameText(value);
        props.nameInputSets.callBack(value);
    }


    const PdfViewer = () => {
        return <div>
            <nav>
                <Button disabled={pageNumber === 1} size={'small'} variant="outlined"
                        onClick={goToPrevPage}>Prev</Button>
                <Button
                    disabled={pageNumber === numPages}
                    className={'m-2'} size={'small'} variant="outlined" onClick={goToNextPage}>Next</Button>
                <p>
                    Page {pageNumber} of {numPages}
                </p>
            </nav>

            <Document
                file={path}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page
                    pageNumber={pageNumber}/>
            </Document>
        </div>
    }

    const ImagesViewer = () => {
        return !nameInputSets.value
            ? <PinchZoomPan minScale={0.5} maxScale={3}>
                <div className={classes.dialogDescription}
                     dangerouslySetInnerHTML={{__html: description}}></div>
            </PinchZoomPan>
            : <div className={classes.dialogDescription}
                   dangerouslySetInnerHTML={{__html: description}}></div>
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            maxWidth={viewerType === 'pdf' ? 'md' : 'sm'}
            onClose={handleClose}
            className="dialogBlock"
        >
            <DialogTitle className="dialogTitle">{title}</DialogTitle>

            <DialogContent>
                <DialogContentText className="dialogDescription">
                    {viewerType === 'pdf' ? PdfViewer() : ImagesViewer()}
                </DialogContentText>
                {nameInputSets.value &&
                <div className="form-group">
                    <InputField
                        type="text"
                        label={<Translate>{nameInputSets.label}</Translate>}
                        onChange={handleNameChange}
                        value={renameText}
                        variant='outlined'
                    />
                </div>
                }
            </DialogContent>

            <DialogActions className="dialogButtons">
                <Button onClick={handleClose} variant="contained"
                        color="secondary"><Translate>Cancel</Translate></Button>
                {handleSubmit && <Button onClick={handleSubmit} variant="contained"
                                         color="primary"><Translate>Submit</Translate></Button>}
            </DialogActions>

        </Dialog>
    );
}
